import React from "react";
import blog1 from "../../../assets/img/product-detail/fertilizer-application1.jpg";
import blog2 from "../../../assets/img/product-detail/fertilizer-application-bg1.jpg";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useGetBlogCatQuery } from "../../products/productSlice";

function BlogAside({ t }) {
  const { data, isLoading } = useGetBlogCatQuery();
  return (
    <>
      <div className="blogAsideSec">
        <div className="blogCategory">
          <h4 className="asideTitle">{t("blog category")}</h4>
          <hr />
          <ul>
            <li>
              <Link to="/">Hair</Link>
            </li>
            <li>
              <Link to="/">Salon</Link>
            </li>
            <li>
              <Link to="/">Extension</Link>
            </li>
            <li>
              <Link to="/">Product</Link>
            </li>
            <li>
              <Link to="/">Team</Link>
            </li>
            <li>
              <Link to="/">Makeup</Link>
            </li>
          </ul>
        </div>
        <div className="recentPosts  ">
          <h4 className="asideTitle">recent posts</h4>
          <hr />
          <div className="recentItemInfo">
            {/* <div className="recentImg">
              <img src={blog1} alt="Latest Blog" />
            </div> */}
            <div className="recentContent">
              <h6>
                <Link to="/blog-detail">Makeover / changing look</Link>
              </h6>
              <p className="date">February 26, 2018</p>
            </div>
          </div>
          <div className="recentItemInfo  ">
            {/* <div className="recentImg">
              <img src={blog2} alt="Latest Blog" />
            </div> */}
            <div className="recentContent">
              <h6>
                <Link to="/blog-detail">
                  Blowdry after 9:30pm and before 9:30am
                </Link>
              </h6>
              <p className="date">July 09, 2015</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogAside;
