import React, { useEffect } from "react";

import etg from "../../../assets/img/brands/etg.png";
import kynoch from "../../../assets/img/brands/kynoch.png";
import falcon from "../../../assets/img/brands/falcon.png";
import oemff from "../../../assets/img/brands/oemff.png";
import "./Brands.css";
import { Link } from "react-router-dom";
import { useListAllBrandQuery } from "../../products/productSlice";
import axios from "axios";
import { AiFillCar } from "react-icons/ai";
import { RiArrowGoForwardLine } from "react-icons/ri";
import { FaMoneyBill } from "react-icons/fa";
import { BsShieldLockFill } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { FiPercent } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const imgs = [etg, kynoch, falcon, oemff];

function Brands() {
  // const { data, isLoading, error } = useListAllBrandQuery()
  const { t } = useTranslation();


  return (
    <>
      <section className="brandsSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="keyInfo">
                <div className="icon">
                  <AiFillCar />
                </div>
                <div className="content">
                  <h5>{t("Free Shipping")}</h5>
                  <p>{t("Free same day delivery within on orders above...")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="keyInfo">
                <div className="icon">
                  <RiArrowGoForwardLine />
                </div>
                <div className="content">
                  <h5>{t("Full Refund")}</h5>
                  <p>
                    {t("Full Refund Text")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="keyInfo">
                <div className="icon">
                  <FaMoneyBill />
                </div>
                <div className="content">
                  <h5>{t("Debit & Credit Card")}</h5>
                  <p>
                    {t("Debit & Credit Card Text")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="keyInfo">
                <div className="icon">
                  <BsShieldLockFill />
                </div>
                <div className="content">
                  <h5>{t("100% Secure Payment")}</h5>
                  <p>
                    {t("100% Secure Payment Text")}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="keyInfo">
                <div className="icon">
                  <BiSupport />
                </div>
                <div className="content">
                  <h5>{t("24/7 Support")}</h5>
                  <p>{t("24/7 Support Text")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="keyInfo">
                <div className="icon">
                  <FiPercent />
                </div>
                <div className="content">
                  <h5>{t("Free Coupon")}</h5>
                  <p>
                    {t("Free Coupon Text")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Brands;
