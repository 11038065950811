import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import BlogAside from "./blog-aside/BlogAside";
import blogBanner from "../../assets/img/360_F_596748409_1wD6AXZCDsJnvEmXdszFGiL0gr267AXI.jpg";

import BlogItem from "./blog-item/BlogItem";
import { useTranslation } from "react-i18next";

function Blog() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Breadcrumb title="Blog" t={t} />
      <section className="blogSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <img src={blogBanner} className="mb-4" alt="Blog" />
            </div>
            <div className="col-md-8">
              <BlogItem />
            </div>
            <div className="col-md-4">
              <BlogAside t={t} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
