import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import HandicraftImage from "./handicraft-image/HandicraftImage";
import CatagProduct from "./CatagPro/CatagProduct";
import axios from "axios";
import { base_url } from "../../server";
import PopularProduct from "./popular";
import { PiPhoneCall } from "react-icons/pi";
import { TbMailCheck } from "react-icons/tb";

import etg from "../../assets/img/brands/etg.png";
import kynoch from "../../assets/img/brands/kynoch.png";
import falcon from "../../assets/img/brands/falcon.png";
import oemff from "../../assets/img/brands/oemff.png";
import artboard from "../../assets/img/brands/artboard.jpg";
import bayer from "../../assets/img/brands/bayer.jpg";
import dhanuka from "../../assets/img/brands/dhanuka.jpg";
import namdhari from "../../assets/img/brands/namdhari.jpg";

import vd from "../../assets/img/db5c829e4bf145a0ad687086d27c4182.mp4";
import insta1 from "../../assets/img/8114C3602F9A3_transcode_oil_output_dashin-4t.jpg";
import insta2 from "../../assets/img/363744_7557071072072370537-4t.jpg";
import insta3 from "../../assets/img/11096822_1595427965024337920-4t.jpg";
import insta4 from "../../assets/img/00345564_8326240976643339204-4t.jpg";

import review1 from "../../assets/img/review-1.jpg";

import bgAttach from "../../assets/img/footer.webp";

import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import KeyCover from "./key-cover/KeyCover";
import LatestNews from "./latest-news/LatestNews";
import { FaPlay } from "react-icons/fa";
import ProductVideo from "./product-video/ProductVideo";
import Brief from "./brief/Brief";
import Team from "./team/Team";
import { useTranslation } from "react-i18next";

function Home() {
  const productData = useContext(productItemHome);
  const { t } = useTranslation();

  const baseUrl = base_url();
  const [data, setData] = useState(null);
  const getata = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/filter/categ`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getata();
  }, []);
  return (
    <>
      <Banner />
      <ServiceList />
      <ProductsCategory />
      <PopularProduct />
      {/* <Products productData={productData} /> */}
      <Team />
      <FeaturedProducts />
      {/* <ProductVideo /> */}
      {data &&
        data?.slice(0, 3).map((item, i) => {
          return <CatagProduct key={i} item={item} />;
        })}

      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      <section className="sellerCategorySec d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>
      <Brief />
      <section className="brandsSec bg-white pb-0">
        <div className="container">
          <div className="brandsHeader">
            <div className="fisherman-content">
              <h3>{t("Partners")}</h3>
            </div>
          </div>
          <div className="brandsItem">
            <marquee behavior="scroll" direction="left">
              <ul>
                <li>
                  <Link to={"/"}>
                    <img
                      src={etg}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <img
                      src={falcon}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <img
                      src={kynoch}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <img
                      src={oemff}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>

                <li>
                  <Link to={"/"}>
                    <img
                      src={artboard}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <img
                      src={bayer}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <img
                      src={dhanuka}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <img
                      src={namdhari}
                      alt="Brand"
                      title="Brand"
                      className="img-fluid"
                    />
                  </Link>
                </li>
              </ul>
            </marquee>
          </div>
        </div>
      </section>
      <div className="testimonialSec">
        <div
          className="attachmentImg"
          style={{
            backgroundImage: `url(${bgAttach})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginTop: "40px",
            filter: "hue-rotate(45deg)",
            backgroundPosition: "top",
          }}
        >
          <div className="container">
            <div className="attachTitle">
              <h6>{t("Male / Female Hairdressers")}</h6>
              <h2>{t("24/ 7 Home Service")}</h2>
            </div>
          </div>
        </div>
        <div className="touchInfo d-none">
          <div className="container">
            <div className="touchText">
              <h3>Follow Us On Instagram </h3>
              <ul>
                <li>
                  <div className="icon">
                    <img src={insta1} alt="Insta" className="img-fluid" />
                  </div>
                  <div className="text">
                    <span>In-Stores</span>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src={insta2} alt="Insta" className="img-fluid" />
                  </div>
                  <div className="text">
                    <span>Collabs</span>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src={insta3} alt="Insta" className="img-fluid" />
                  </div>
                  <div className="text">
                    <span>Women's Wear</span>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src={insta4} alt="Insta" className="img-fluid" />
                  </div>
                  <div className="text">
                    <span>New Arrivals</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="clientSay">
          <div className="fisherman-content">
            <h3>{t("What Client Say")}</h3>
          </div>
          <div className="reviewList">
            <ul>
              <li>
                <div className="header">
                  <div className="figure">
                    <img src={review1} alt="review" className="img-fluid" />
                  </div>
                  <div className="text">
                    <div className="rating">
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                    </div>
                    <h3>{t("Elic Semules")}</h3>
                    <h6>{t("Customer")}</h6>
                  </div>
                </div>
                <p>
                  {t("I realized after I sent in my order that I had ordered a larger quantity than I needed. I emailed and received a quick response, adjustment to my order and a refund. Product was shipped out in a timely manner.")}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <KeyCover /> */}
      <LatestNews />
      {/* <BestSeller /> */}
      <Network />
      {/* <AddingShow /> */}

      {/* <HandicraftImage /> */}
      {/* <CostInfo /> */}
      <Brands />
    </>
  );
}

export default Home;
