import React from "react";

import home1 from "../../../assets/img/2469fb403046d261b25ed7babd02d9e4.jpg";
import home2 from "../../../assets/img/459da5f48ed22e3be7844c51ad0c5c54.jpg";
import logovideo from "../../../assets/img/logo-video-main.mp4";

import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
import { useTranslation } from "react-i18next";
function ServiceList() {
  const { t } = useTranslation();

  return (
    <>
      <section className="serviceList sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="aboutWooden">
                <span>
                  {t(
                    "Hair / nails / lashes / makeup / team / salons / product / extensions"
                  )}{" "}
                </span>
                <h2>
                  {t("The Best Salon In Dubai 24/7 Home Service Beauty Salon")}
                </h2>
                <p>
                  {t(
                    "Introducing 24DXB: Your 24/7 Beauty Destination. Hair, makeup, nails, lashes, Bridal , Events . All at your fingertips, in-salon or at home."
                  )}
                </p>
                <Link to={"/"}>{t("Book Hair and Makeup")}</Link>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="aboutImg">
                <video
                  src={logovideo}
                  muted
                  loop
                  autoPlay
                  width={361}
                  height={460}
                ></video>
                <img src={home1} alt="About" className="img-fluid" />
                {/* <img src={home2} alt="About" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
