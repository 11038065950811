import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetFeaturedProductQuery } from "../../products/productSlice";
import FeaturedProduct from "./featured-product/FeaturedProduct";
import QuiekViewModal from "../../../pages/QueikViewModal/QuiekViewModal";
import { featuredDB } from "../../../rki-data/category";
import axios from "axios";
import { base_url } from "../../../server";
import { useTranslation } from "react-i18next";

import product1 from "../../../assets/img/serviceList/1.webp";
import product2 from "../../../assets/img/serviceList/2.webp";
import product3 from "../../../assets/img/serviceList/3.webp";
import product4 from "../../../assets/img/serviceList/4.webp";
import product5 from "../../../assets/img/serviceList/5.webp";
import product6 from "../../../assets/img/serviceList/6.webp";
import product7 from "../../../assets/img/serviceList/7.webp";
import product8 from "../../../assets/img/serviceList/8.webp";
import product9 from "../../../assets/img/serviceList/9.webp";
import product10 from "../../../assets/img/serviceList/10.webp";
import product11 from "../../../assets/img/serviceList/11.webp";
import product12 from "../../../assets/img/serviceList/12.webp";

import { AiFillStar } from "react-icons/ai";
const featuredItems = [
  {
    id: "1",
    imgUrls: product1,
    title: "Blowdry Short to Medium",
    price: "160",
  },
  {
    id: "2",
    imgUrls: product2,
    title: "Blowdry Medium to Long",
    price: "180",
  },
  {
    id: "3",
    imgUrls: product3,
    title: "Blowdry 24/7",
    price: "250 - 450",
  },
  {
    id: "4",
    imgUrls: product4,
    title: "Haircut & Blowdry",
    price: "250",
  },
  {
    id: "5",
    imgUrls: product5,
    title: "Haircut",
    price: "150",
  },
  {
    id: "6",
    imgUrls: product6,
    title: "Classic Mani/pedi",
    price: "250",
  },
  {
    id: "7",
    imgUrls: product7,
    title: "9 Blowouts Package 24/7",
    price: "1250",
  },
  {
    id: "8",
    imgUrls: product8,
    title: "15 Premium Blowout Package 250 person only",
    price: "2250",
  },
  { id: "9", imgUrls: product9, title: "Roots color", price: "250" },
  { id: "10", imgUrls: product10, title: "Fixing Extensions", price: "350" },
  { id: "11", imgUrls: product11, title: "Gel Mani/pedi", price: "380" },
  {
    id: "12",
    imgUrls: product12,
    title: "Bridal Hair And Makeup",
    price: "2500-3500",
  },
];

function FeaturedProducts() {
  const [data, setData] = useState();
  const [error, seterror] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const baseUrl = base_url();
  const getdata = async (page) => {
    setisLoading(true);
    try {
      // const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/${page}&${12}`, { withCredentials: true })
      const res = await axios.get(`${baseUrl}product/page/0&10000`, {
        withCredentials: true,
      });
      setData(res.data);
      setisLoading(false);
    } catch (error) {
      console.log("Server Error !");
      seterror(true);
      setisLoading(false);
    }
  };
  useEffect(() => {
    getdata(0);
  }, []);

  const [modelDataId, setModelDataId] = useState(null);
  const setProduct_id = (id) => {
    setModelDataId(id);
  };

  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id);
    setShow(true);
  };

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  }, [data]);

  return (
    <>
      <section className="productsSection ">
        <div className="container">
          <div className="row featuredRow d-none">
            <div className="col-lg-12">
              <div className="fishermanHeader ">
                <div className="fisherman-content">
                  <h3>{t("Trending Products")}</h3>
                </div>
              </div>
            </div>
            <FeaturedProduct
              data={sortedData}
              getFeaturedPro={getdata}
              isLoading={isLoading}
              error={error}
              handleShow={handleShow}
              setProduct_id={setProduct_id}
            />
          </div>

          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader ">
                <div className="fisherman-content">
                  <h3>{t("Trending Products")}</h3>
                </div>
              </div>
            </div>

             <FeaturedProduct
              data={sortedData}
              getFeaturedPro={getdata}
              isLoading={isLoading}
              error={error}
              handleShow={handleShow}
              setProduct_id={setProduct_id}
            /> 

            {/* <div className="serviceItemInfo">
              <div className="row">
                {featuredItems.map((item) => {
                  return (
                    <div className="col-md-4" key={item.id}>
                      <div className="serviceItem">
                        <div className="serviceItemImg">
                          <img
                            src={item.imgUrls}
                            className="img-fluid"
                            alt={item.title}
                            title={item.title}
                          />
                        </div>
                        <div className="serviceItemText1">
                          <h5>
                            <Link to="/products">
                              {item.title}{" "}
                              <i className="ri-arrow-right-up-fill"></i>
                            </Link>
                          </h5>
                          <p>AED{item.price}.00</p>
                          <div className="buyNowInfo">
                            <Link className="btn buyNow cart" to="/">
                              Add to Cart{" "}
                              <i className="ri-arrow-right-up-fill" />
                            </Link>
                            <Link className="btn buyNow" to="/">
                              Buy Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>
        </div>

        {/* {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )} */}
      </section>
    </>
  );
}

export default FeaturedProducts;
