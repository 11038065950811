import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import home1 from "../../../assets/img/banner/20.jpg";
import logovideo from "../../../assets/img/logo-video.mp4";

// import home2 from "../../../assets/img/banner/banner2.png";
// import home3 from "../../../assets/img/banner/banner3.png";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
import { useTranslation } from "react-i18next";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const { t } = useTranslation();

  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      console.log("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="bannerSection">
        <Slider {...settings}>
          <div className="bannerItem">
            <Link to={"/"}>
              <img src={home1} className="img-fluid" title="24beauty" />
              <div className="bannerText">
                <h6>{t("24/ 7 Home Service")}</h6>
                <h2>{t("Imagine your life with fashion and style")}</h2>
                <p>
                  {t(
                    "Build a website as impressive as your ultrauniq with this attractive and professionals."
                  )}
                </p>

                <Link to="/" className="btn">
                {t("book or call by whatsapp")}
                </Link>
                <Link to="/" className="btn ms-3">
                  {t("call now")}
                </Link>
              </div>
            </Link>
          </div>
          {/* <div className="bannerItem">
            <Link to={"/"}>
              <img src={home2} className="img-fluid" title="24beauty" />
            </Link>
          </div>
          <div className="bannerItem">
            <Link to={"/"}>
              <img src={home3} className="img-fluid" title="24beauty" />
            </Link>
          </div>*/}
        </Slider>

        {/* <Slider {...settings}> 
          {data &&
            data.slice(0, 2).map((item) => {
              return (
                item?.image?.url && (
                  <Link to={item?.url || ""} key={item._id}>
                    <div className="bannerItem">
                      <img src={item?.image.url} className="img-fluid" />
                      <div className="bannerText d-none">
                        <h6>Fashion show with designer</h6>
                        <h2>the best collection</h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Porro ab dolor incidunt harum cupiditate ea
                          nesciunt earum commodi adipisci
                        </p>
                        <Link to="/" className="btn">
                          shop now
                        </Link>
                      </div>
                    </div>
                  </Link>
                )
              );
            })}
         </Slider> */}
      </section>
    </>
  );
}

export default Banner;
