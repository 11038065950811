import React, { useEffect, useState } from "react";
import logovideo from "../../assets/img/logo-video.mp4";
import mainImg from "../../assets/img/1brimcompany-1024x1024.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { base_url } from "../../server";
import axios from "axios";
// import service1 from "../../assets/img/rki/network/1.jpg";
// import service2 from "../../assets/img/rki/network/2.jpg";
// import service3 from "../../assets/img/rki/network/3.jpg";
// import service4 from "../../assets/img/rki/network/4.jpg";
// import service5 from "../../assets/img/rki/network/5.jpg";
// import service6 from "../../assets/img/rki/network/6.webp";

// const categoriesItems = [
//   { id: "1", imgUrls: service1, title: "Hair" },
//   { id: "2", imgUrls: service2, title: "nails" },
//   { id: "3", imgUrls: service3, title: "lashes" },
//   { id: "4", imgUrls: service4, title: "makeup" },
//   { id: "5", imgUrls: service5, title: "salons" },
//   { id: "6", imgUrls: service6, title: "extensions" },
// ];

const Home1 = () => {
  const { t } = useTranslation();
  const [categoriesData, setCateData] = useState(null);
  const baseUrl = base_url();
  const getcateData = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/public`, {
        withCredentials: true,
      });
      setCateData(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getcateData();
  }, []);

  return (
    <>
      <section className="infoSec d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="description">
                <video
                  src={logovideo}
                  muted
                  loop
                  autoPlay
                  width={361}
                  height={460}
                ></video>

                <div className="aboutWooden main">
                  <div className="link">
                    <a
                      href="https://wa.me/+971555315392"
                      target="_blank"
                      className="bookNow"
                      rel="noopener noreferrer"
                    >
                      Book Now by whatsapp / احجز الان عبر الواتساب🟢🟢
                    </a>
                    <a
                      href="tel:+971588091334"
                      target="_blank"
                      className="bookNow"
                    >
                      Call Now 🤳/ اتصل الان
                    </a>
                  </div>

                  <h2>
                    {t(
                      "The Best Salon In Dubai 24/7 Home Service Beauty Salon"
                    )}
                  </h2>
                  <span className="d-block">
                    {t(
                      "Hair / nails / lashes / makeup / team / salons / product / extensions"
                    )}{" "}
                  </span>
                  <hr />
                  <p>
                    {t(
                      "Introducing 24beauty: Your 24/7 Beauty Destination. Hair, makeup, nails, lashes, Bridal , Events . All at your fingertips, in-salon or at home."
                    )}
                  </p>
                  <div className="link book">
                    <Link to="/login" target="_blank" className="bookNow me-2">
                      Customer Login
                    </Link>
                    <Link
                      to="/registration"
                      target="_blank"
                      className="bookNow me-2"
                    >
                      Customer Register
                    </Link>
                    <Link to="/main" target="_blank" className="bookNow">
                      Home Page
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="mainImg">
                <img src={mainImg} alt="mainImg" className="img-fluid" />
                <a
                  href="https://wa.me/+971555315392"
                  className="bookNow"
                  target="_blank"
                >
                  book now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="infoSec">
        <div className="comingSoonBox">
          <div className="description">
            <video
              src={logovideo}
              muted
              loop
              autoPlay
              width={341}
              height={440}
            ></video>
            <div className="parentBox">
              <div className="comingSoonText">
                <h2>Comin Soon</h2>
              </div>
              <div className="aboutWooden main">
                <div className="link">
                  <a
                    href="https://wa.me/+971555315392"
                    target="_blank"
                    className="bookNow"
                    rel="noopener noreferrer"
                  >
                    Book Now by whatsapp / احجز الان عبر الواتساب🟢🟢
                  </a>
                  <a
                    href="tel:+971588091334"
                    target="_blank"
                    className="bookNow"
                  >
                    Call Now 🤳/ اتصل الان
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="models">
        <div className="container">
          <ul>
            {categoriesData?.map((item) => {
              return (
                <li key={item.id}>
                  <Link to={`/product/category/${item.uid}/${item?.slug}`}>
                  <img
                    src={item?.icon?.url}
                    alt={item?.name}
                    className="img-fluid"
                  />
                  <span className="text">{item?.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div> */}
    </>
  );
};

export default Home1;
