import React from "react";

import p9 from "../../../assets/img/p9i9wsc0r9st4kofjmbd.jpg";
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img src={p9} alt="" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="container">
                <h3 className="subtitle">
                  {t("24beauty Wedding Dress Dubai - Cherish Your Memories")}
                </h3>
                {/* <p className="">{data?.about_company}</p> */}
                {/* <p>{t("about-1")}</p> */}
                <p>
                  Welcome to our exquisite boutique, where we pride ourselves in
                  offering an extensive collection of ladies' garments that
                  embody elegance, style, and sophistication. Step into our
                  world of fashion and discover a carefully curated selection of
                  clothing that caters to the diverse tastes and preferences of
                  contemporary women. From chic dresses for special occasions to
                  comfortable yet fashionable everyday wear, we have something
                  to suit every wardrobe need.
                </p>
                <p>
                  Our garments are crafted with meticulous attention to detail,
                  using high-quality materials that ensure both comfort and
                  durability. Whether you're searching for the perfect ensemble
                  for a night out or a versatile piece that can be dressed up or
                  down, our collection has it all.
                </p>
                <p>
                  At our boutique, we understand that fashion is not just about
                  the clothes you wear, but also about expressing your
                  individuality. That's why our knowledgeable staff is always on
                  hand to provide personalized styling advice, helping you
                  create unique and unforgettable looks.
                </p>
                <p>
                  We believe that every woman deserves to feel confident and
                  beautiful, and our selection of garments is designed to
                  empower and inspire. With our commitment to exceptional
                  customer service, we strive to make your shopping experience
                  enjoyable and stress-free.
                </p>
                <p>
                  So, why settle for ordinary when you can embrace
                  extraordinary? Visit our boutique today and indulge in the
                  world of exquisite fashion, where timeless elegance meets
                  contemporary trends.
                </p>
              </div>
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
