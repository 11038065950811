
import img1 from "../../../assets/img/brands/etg.png";
import img2 from "../../../assets/img/brands/kynoch.png";
import img3 from "../../../assets/img/brands/falcon.png";
import img4 from "../../../assets/img/brands/oemff.png";
import img5 from "../../../assets/img/brands/artboard.jpg";
import img6 from "../../../assets/img/brands/bayer.jpg";
import img7 from "../../../assets/img/brands/dhanuka.jpg";
import img8 from "../../../assets/img/brands/namdhari.jpg";


export const BrandImg = [
    { url: img1 },
    { url: img2 },
    { url: img3 },
    { url: img4 },
    { url: img5 },
    { url: img6 },
    { url: img7 },
    { url: img8 },
]