import React from 'react'
import { Link } from 'react-router-dom'
import brief from "../../../assets/img/big.webp"
import { useTranslation } from 'react-i18next';

const Brief = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className='briefSec'>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <img src={brief} alt="brief" className='img-fluid' />
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="briefText">
                <h3>{t("24/7 home beauty services")}</h3>
                <ul>
                  <li>
                  <Link to="/">{t("Price List")}</Link>
                  </li>
                  <li>
                  <Link to="/">{t("Check our team")}</Link>
                  </li>
                  <li>
                  <Link to="/">{t("Whatsapp 24/7")}</Link>
                  </li>
                  <li>
                  <Link to="/">{t("call Now 24/7")}</Link>
                  </li>
                  <li>
                  <Link to="/">{t("Coming Soon")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Brief