import React from "react";

function ContactInfo({t}) {
  return (
    <>
      <div className="contactInfo">
        <h4 className="ls-n-25 m-b-1">{t('Contact Info')}</h4>
        <p>
          {t('conuse')}
        </p>
      </div>
    </>
  );
}

export default ContactInfo;
