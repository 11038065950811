import React from "react";
import { Link } from "react-router-dom";
import blog1 from "../../../assets/img/serviceList/new1.jpg";
import blog2 from "../../../assets/img/serviceList/new2.jpg";
import blog3 from "../../../assets/img/serviceList/new3.jpg";
import blog4 from "../../../assets/img/serviceList/new4.jpg";

import { MdOutlineAdminPanelSettings, MdPhoneInTalk } from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useGetBlogsQuery } from "../../products/productSlice";
function BlogItem() {
  const { data, isLoading } = useGetBlogsQuery();
  return (
    <>
      <section className="blogItemSec">
        <div className="row">
          {isLoading && (
            <div className="preloaderCount">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
         
          <div className="col-lg-6">
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog1} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">25</span>
                  <span className="month">feb</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Makeover / changing look</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog1} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">25</span>
                  <span className="month">feb</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Makeover / changing look</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog4} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">25</span>
                  <span className="month">mar</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Roots hair colore and free Blowdry</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog2} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">07</span>
                  <span className="month">Nov</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Makeover / changing look</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog3} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">01</span>
                  <span className="month">July</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Makeover / changing look</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog4} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">25</span>
                  <span className="month">feb</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Blowdry after 9:30pm and before 9:30am</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog1} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">25</span>
                  <span className="month">feb</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Hair Treatment ( cold or hot)</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="blogItemInfo">
              <div className="blogItemFigure">
                <img src={blog1} className="img-fluid" alt="Blog" />
                <div className="postDate">
                  <span className="date">25</span>
                  <span className="month">feb</span>
                </div>
              </div>
              <div className="blogItemContent">
                <div className="author">
                  <ul>
                    <li>
                      <Link to={`/}`}>
                        <MdOutlineAdminPanelSettings />
                        By Ismail
                      </Link>
                    </li>
                    <li>
                      <Link to={`/}}`}>
                        {" "}
                        <MdPhoneInTalk /> Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4>Full head Highlights </h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur fuga a itaque eligendi ea beatae ipsa iure
                    reiciendis, eum sapiente omnis praesentium molestiae magni
                    cumque perspiciatis molestias at voluptatibus. Nemo?
                  </p>
                </div>
                <div className="readMore">
                  <Link to={`/}`}>
                    Read More <HiOutlineArrowNarrowRight />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogItem;
