import React from "react";
import product1 from "../../../assets/img/team/1.webp";
import product2 from "../../../assets/img/team/2.webp";
import product3 from "../../../assets/img/team/3.webp";
import product4 from "../../../assets/img/team/4.webp";
import product5 from "../../../assets/img/team/5.webp";
import product6 from "../../../assets/img/team/6.webp";
import product7 from "../../../assets/img/team/7.webp";
import product8 from "../../../assets/img/team/8.webp";
import { useTranslation } from "react-i18next";
const featuredItems = [
  {
    id: "1",
    imgUrls: product1,
    title: "Bashar Ladies Hairdresser",
  },
  {
    id: "2",
    imgUrls: product2,
    title: "Ali  ladies hairdresser abo dhabi",
  },
  {
    id: "3",
    imgUrls: product3,
    title: "Hiba Makeup Artist ",
  },
  {
    id: "4",
    imgUrls: product4,
    title: "Alesha Nails Expert",
  },
  {
    id: "5",
    imgUrls: product5,
    title: "Bagul makeup",
  },
  {
    id: "6",
    imgUrls: product6,
    title: " Ladies Hair Dresser",
  },
  {
    id: "7",
    imgUrls: product7,
    title: "Lashes expert",
  },
  {
    id: "8",
    imgUrls: product8,
    title: "Ladies hairdresser",
  },
];
const Team = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="teamSec">
        <div className="container">
          <div className="fisherman-content">
            <h3>{t("Team And New Services")}</h3>
          </div>
          <div className="teamBody">
            {featuredItems.map((item) => {
              return (
                <div className="teamCard" key={item.id}>
                  <img
                    src={item.imgUrls}
                    alt={item.title}
                    className="img-fluid"
                  />
                  <h4>{item.title}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
