import React from "react";
import { Link } from "react-router-dom";
import image1 from "../../../assets/img/kriti-ds-indian-makeup-golden-tones.jpg";
import image2 from "../../../assets/img/rs=w_1160,h_1547.webp";
import image3 from "../../../assets/img/img3_667a7116-d399-44b9-be70-4e638d1c753730a930a930a9.jpg";
import image4 from "../../../assets/img/ab3.webp";
import image5 from "../../../assets/img/ab1.webp";
import image6 from "../../../assets/img/ab2.webp";
import { useTranslation } from "react-i18next";

const LatestNews = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="LatestSec">
        <div className="container">
          <div className="latestNewCard">
            <div className="latestNewsImage">
              <img src={image1} alt="image1" className="img-fluid" />
            </div>
            <div className="latestNewsText">
              <h3>{t("Makeup Pro")}</h3>
              <p>
                {t("Makeup Pro Text")}
              </p>
              <Link to={"/"}>{t("book jessy & sasha")}</Link>
            </div>
          </div>

          <div className="latestNewCard">
            <div className="latestNewsText">
              <h3>{t("Acrylic Extension & Nail Art")} </h3>
              <p>
                {t("Acrylic Extension & Nail Art Text")}
              </p>
              <Link to={"/"}>{t("book phong & may")}</Link>
            </div>
            <div className="latestNewsImage">
              <img src={image2} alt="image1" className="img-fluid" />
            </div>
          </div>

          <div className="latestNewCard">
            <div className="latestNewsImage">
              <img src={image3} alt="image1" className="img-fluid" />
            </div>
            <div className="latestNewsText">
              <h3>{t("Hair treatment / keratin / Cadivu / Quatrolium")}</h3>
              <p>
                {t("Hair treatment Text")}
              </p>
              <Link to={"/"}>{t("book now")}</Link>
            </div>
          </div>

          <div className="latestNewCard">
            <div className="latestNewsText">
              <h3>{t("Crown of Africa: Braid your Way to Royalty")} </h3>
              <p>
                {t("Crown of Africa Text")}
              </p>
              <Link to={"/"}>{t("book now")}</Link>
            </div>
            <div className="latestNewsImage">
              <img src={image5} alt="image1" className="img-fluid" />
            </div>
          </div>
          <div className="latestNewCard">
            <div className="latestNewsImage">
              <img src={image6} alt="image1" className="img-fluid" />
            </div>
            <div className="latestNewsText">
              <h3>{t("Hair Tape Extensions Natural 100% Remy Hair")}</h3>
              <p>
                {t("Extensions Natural")}
              </p>
              <Link to={"/"}>{t("book now")}</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestNews;
