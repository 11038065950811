import mamaspro from "../../assets/img/products/mp4.1.webp";
import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import { CustomToaster } from "../../common/toaster/CustomToaster";
import { useEffect, useState } from "react";
import { setCartLeng, useSetCartMutation } from "../products/productSlice";
import { useDispatch } from "react-redux";

function CatogaryItem({ item, i, handleShow, t }) {
  const curr = window.localStorage.getItem("currencySym");
  let currencySymbol = curr;
  if (currencySymbol === "undefined") {
    currencySymbol = " QAR";
  }
  const [showTaoster, setShowToaster] = useState({
    show: false,
    message: "",
    color: "success",
  });
  const navigate = useNavigate();
  const handleToaster = () => {
    setShowToaster({ ...showTaoster, show: false });
  };
  const [names, setNames] = useState("");
  const [
    addToCart,
    {
      data: datacart,
      isLoading: isAddCartLoading,
      isSuccess: isAddToCartSuccess,
      isError: isAddToCartError,
    },
  ] = useSetCartMutation();
  const BuyNowItem = (item, isAddTocard) => {
    // if (window.localStorage.getItem('isLogin') == 'false') {
    //     alert('Login First')
    //     return
    // }
    setNames(item.name);
    const payload = {
      qty: 1,
      pickupPoint: null,
      variantId: item.variations[0]?.uid,
      productId: item.uid,
      deliveryType: "HOME DELIVERY",
      seller_id: item.prices?.seller_id,
      sku: item.prices?.sku,
    };
    addToCart(payload);
    if (!isAddTocard) {
      setTimeout(() => {
        navigate("/checkout");
      }, 1000);
    }
  };
  const dispacher = useDispatch();
  useEffect(() => {
    if (isAddToCartSuccess) {
      dispacher(setCartLeng(datacart?.cartLength));
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
      window.localStorage.setItem("cartItem", datacart?.cart);
    }
    if (isAddToCartError) {
      setShowToaster({
        show: true,
        message: "Something went wrong Product Not Add",
        color: "danger",
      });
    }
  }, [isAddToCartSuccess, isAddToCartError]);
  // const handleAddCart = (item) => {
  //   setNames(item.name);
  //   const payload = {
  //     qty: 1,
  //     pickupPoint: null,
  //     variantId: item.variations[0]?.uid,
  //     productId: item.uid,
  //     deliveryType: "HOME DELIVERY",
  //     seller_id: item.prices?.seller_id,
  //     sku: item.prices?.sku,
  //   };
  //   addToCart(payload);
  // };

  return (
    <div className="col-lg-3 col-md-6 col-sm-12 cusname" key={item._id}>
      <CustomToaster
        color={showTaoster.color}
        title={names}
        show={showTaoster.show}
        setShow={handleToaster}
        message={showTaoster.message}
        position="bottom-end"
        delay={10000}
      />
      <div className="serviceItem">
        <div className="serviceItemImg">
          <Link to={`/product/${item.uid}/${item.slug}`}>
            {item?.variations[0]?.mainImage_url?.url ? (
              <>
                <img
                  src={item?.variations[0]?.mainImage_url.url}
                  alt="Product"
                  className="imgProduct"
                />

                <div className="buyNowInfo">
                  <Link
                    to={`#`}
                    onClick={() => BuyNowItem(item, true)}
                    className="btn buyNow cart"
                  >
                    {t("Add to Cart")}
                  </Link>

                  <Link
                    to="#"
                    className="btn buyNow"
                    onClick={() => {
                      BuyNowItem(item);
                    }}
                  >
                    {t("Buy Now")}
                  </Link>
                </div>
              </>
            ) : (
              <img src={mamaspro} alt="Product" />
            )}
          </Link>
          <div className="quickView d-none">
            <ul>
              <li className="viewProduct">
                <button
                  className="quick_view_btn"
                  onClick={(e) => {
                    handleShow(item._id);
                  }}
                >
                  <FiSearch />
                </button>
              </li>
              <li className="addProduct">
                <Link to="/products">
                  <GrAdd />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ul className="hotList d-none">
          <li>
            <Link to="/products">hot</Link>
          </li>
          <li>
            <Link to="products">-20%</Link>
          </li>
        </ul>
        <div className="serviceItemText1">
          <h5>
            <Link to={`/product/${item.uid}/${item.slug}`}>{item.name}</Link>
          </h5>
          {item?.prices ? (
            <p>
              {item?.country?.code} {item?.prices?.sale_rate}
            </p>
          ) : (
            <p>
              {item?.variations[0]?.prices?.country_id?.code}{" "}
              {item?.variations[0]?.prices?.sale_rate}
            </p>
          )}

          <div className="productDesc">
            <p>{item.meta_description}</p>
          </div>
          <div className="featuredOption">
            <select defaultValue={"DEFAULT"}>
              <option value="DEFAULT">Select Option</option>
              <option value="one">One</option>
              <option value="two">Two</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CatogaryItem;
